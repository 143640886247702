<template>
  <v-container
    id="predicao-historico"
    fluid
    tag="section"
  >
    <add-fixed-button @clicked="addClient()" />

    <v-card-text style="height: 100px; position: relative">

    </v-card-text>

    <v-dialog
      v-model="dialog"
      max-width="290"
    >
      <v-card>
        <v-card-text >
          <p> Quer mesmo deletar o Cliente {{clientToDelete}}? </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="confirmDeleteClient()"
          >
            Confirmar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            text
            @click="dialog = false; clientToDelete = ''"
          >
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col 
        cols="12"
      >
        <base-material-card
          color="indigo"
          icon="mdi-face-man"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-2 font-weight-light">
              Clientes
            </div>
          </template>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            class="ml-auto"
            label="Pesquisar"
            hide-details
            single-line
            style="max-width: 250px;"
          />

          <v-divider class="mt-3" />

          <v-data-table
            :headers="headers"
            :items="items"
            :search.sync="search"
            :sort-desc="[false, true]"
            :footer-props="{
              itemsPerPageText: 'Itens por Página'
            }"
            multi-sort
          >
            <template v-slot:item="row">
              <tr>
                <td class="button">{{row.item.id}}</td>
                <td class="button">{{row.item.name}}</td>
                <td class="button">{{row.item.cpf}}</td>
                <td class="button">{{row.item.email}}</td>
                <td class="button">{{row.item.modified}}</td>
                <td> 

                  <v-btn
                    class="mr-2"
                    outlined
                    fab
                    x-small
                    color="success"
                    @click="makeServiceOrder(row.item.id)"
                  >
                    <v-icon>mdi-clipboard-outline</v-icon>
                  </v-btn>

                  <v-btn
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="info"
                    @click="editClient(row.item.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>

                  <v-btn
                    v-if="user.admin"
                    class="ma-2"
                    outlined
                    fab
                    x-small
                    color="error"
                    @click="deleteClient(row.item.id)"
                  >
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                        
                </td>

              </tr>
            </template>

          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  import ClientsService from '@/services/ClientsService'
  import dayjs from 'dayjs'

  export default {
    name: 'ClientsIndex',

    components: {
      AddFixedButton: () => import('@/components/base/AddFixedButton'),
    },

    data () {
      return {
        dialog: false,
        clientToDelete:"",
        search: '',
        items: [],
        headers: [
        {
          text: '#',
          value: 'id',
        },
        {
          text: 'Nome',
          value: 'name',
        },
        {
          text: 'CPF/CNPJ',
          value: 'document',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Modificado',
          value: 'modified',
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
        },
      ],
      }
    },

    mounted () {
      this.getAllClients()
    },

    computed: {
      user() {
        return JSON.parse(localStorage.getItem('user'))
      },
    },

    methods: {

      addClient () {
        this.$router.push({name: 'Criar Cliente'})
      },

      makeServiceOrder (id) {
        this.$router.push('/orders/add_from_client/'+id)
      },

      editClient (id) {
        this.$router.push('/clients/edit/'+id)
      },

      confirmDeleteClient () {
        const id = this.clientToDelete
        ClientsService.deleteClient(id)
        .then(response => {
          if(response.data.message == "Error"){
            throw 500
          }
          this.items = this.items.filter(element => element.id != id)
          this.$toast.success('Cliente deletado.', '',{position:'topRight'})
          this.dialog = false
        })
        .catch(error => {
          console.log(error)
          this.$toast.error('Erro ao deletar, verifique se não há nenhuma ordem associada.', '',{position:'topRight'})
          this.dialog = false
        })
      },

      deleteClient (id) {
        this.dialog = true
        this.clientToDelete = id
      },

      getAllClients (page) {
        ClientsService.getClients()
        .then(response => {
          this.items = []
          let obj = {}
          response.data.clients.forEach(element => {

            obj = {
              id: element.id,
              name: element.name,
              cpf: element.cpf,
              email: element.email,
              modified: dayjs(element.modified).format('DD/MM/YY'),
            }
            this.items.push(obj);
          });
        })
        .catch(error => console.log(error))
      },
    },

  }
</script>
